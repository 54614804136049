import React from 'react'
import { DimensionsWrap } from '../DimensionsWrap'
import './styles.css'

class FederationsPricing extends React.Component {
  render () {
    return (
      <div className='section'>
        <div className='federations-pricing'>
          <div className='federations-pricing-content'>
            <div className='federations-pricing-info'>
              <div className='federations-pricing-info-title'>
                <span>Your mobile real-time video broadcaster</span>
              </div>
              <div className='federations-pricing-info-text'>
                <span>All-in-one platform to create & share engaging content from your games</span>
              </div>
            </div>
            <div className='federations-pricing-card-container'>
              <DimensionsWrap heightProp={770 / 540} widthProp={540 / 1970} className='pricing-card white shadow'>
                <div className='pricing-card-title shadow'>
                  <span>MONTHLY SUBSCRIPTION</span>
                </div>
                <div className='pricing-card-text'>
                  <span>4,95€</span>
                  <ul>
                    <li>Live stream</li>
                    <li>Live score</li>
                    <li>Video replays</li>
                    <li>Compilations</li>
                    <li>Commercials</li>
                    <li>Empower your audience</li>
                    <li>Restrict access</li>
                  </ul>
                  <a href='#s'>
                    <DimensionsWrap heightProp={55 / 240} widthProp={240 / 1920} className='pricing-btn subscribe'>
                      Subscribe
                    </DimensionsWrap>
                  </a>
                </div>
              </DimensionsWrap>
            </div>
          </div>
          <a href='#Federations-Features'>
            <DimensionsWrap heightProp={37.3 / 42.5} widthProp={42.5 / 1920} className='pricing next-section-arrow'>
              <img src={require('../../assets/svgs/arrows.svg')} alt='arrows' width='100%' height='100%' />
            </DimensionsWrap>
          </a>
        </div>
      </div>
    )
  }
}

export default FederationsPricing
