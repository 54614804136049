import React from 'react'
import './styles.css'
import { DimensionsWrap } from '../DimensionsWrap'

class HowItWorks extends React.Component {
  render () {
    return (
      <div className='section'>
        <div className='how-it-works'>
          <div className='how-it-works-content'>
            <div className='how-it-works-title'>
              <span>How it works</span>
            </div>
            <DimensionsWrap heightProp={520 / 624} widthProp={(624 / 1920)} className='how-it-works-position'>
              <div className='how-it-works-text-container'>
                <p>Position your phone's camera to watch the game</p>
                <div className='hiw-border-skew' />
              </div>
              <div className='how-it-works-text-container'>
                <p>Your camera is now recording</p>
                <div className='hiw-border-skew' />
              </div>
            </DimensionsWrap>
            <DimensionsWrap heightProp={583 / 478} widthProp={(478 / 1920)} className='how-it-works-slide'>
              <div className='how-it-works-text-container'>
                <p>AFTER a cool moment happened, slide the button</p>
                <div className='hiw-border-skew' />
              </div>
              <DimensionsWrap heightProp={58 / 210} widthProp={210 / 1920} className='hiw-hi-lite-button'>
                <img src={require('../../assets/imgs/hilitebutton.png')} alt='hi-lite button' width='100%' height='100%' />
              </DimensionsWrap>
            </DimensionsWrap>
            <DimensionsWrap heightProp={875 / 500} widthProp={(500 / 1920)} className='how-it-works-video'>
              <video src={require('../../assets/videos/hiwvideo-2.mp4')} poster={require('../../assets/imgs/hiwposter.png')} autoPlay loop muted data-keepplaying />
            </DimensionsWrap>
          </div>
        </div>
      </div>
    )
  }
}

export default HowItWorks
