import React from 'react'
import { DimensionsWrap, Footer, VideoBox } from '../../components'
import './styles.css'

class Holder extends React.Component {
  render () {
    return (
      <div className='section'>
        <div className='holder'>
          <div className='holder-content'>
            <div className='holder-content-title'>
              <span>Buy a Holder</span>
            </div>
            <div className='holder-wrapper'>
              <DimensionsWrap heightProp={this.props.cardHeight} widthProp={this.props.cardWidth} className='holder-card shadow'>
                <div className='holder-card-info'>
                  <div className='holder-card-title'>
                    <span>Benefits</span>
                  </div>
                  <div className='holder-card-text'>
                    <ul>
                      <li>Universal mounting - tripod, wall, pipe and more</li>
                      <li>Fix a phone in seconds</li>
                      <li>Full ball hit protection</li>
                    </ul>
                  </div>
                </div>
                {this.props.info &&
                  <div className='holder-card-info-materials'>
                    <div className='holder-card-title'>
                      <span>+ Info materials</span>
                    </div>
                    <div className='holder-card-text'>
                      <span>Placed next to the phone holders, these are inviting your players and coaches to start enjoying SoCourt</span>
                    </div>
                  </div>
                }
                <div className='holder-card-purchase-wrapper'>
                  <div className='holder-card-price-text'><span>Price:</span></div>
                  <div className='holder-card-price-button-wrapper'>
                    <div className='holder-card-price'>
                      <span>20.99€</span>
                    </div>
                    <a href='#'>
                      <DimensionsWrap heightProp={45 / 140} widthProp={140 / 1920} className='pricing-btn subscribe'>
                        <span>Coming soon</span>
                      </DimensionsWrap>
                    </a>
                  </div>
                </div>
              </DimensionsWrap>
              <div className='holder-slider-wrapper'>
                <DimensionsWrap heightProp={102 / 675} widthProp={675 / 1920} className='holder-logo-horizontal'>
                  <img src={(require('../../assets/svgs/socourt.svg'))} alt='socourt' width='100%' height='100%' />
                </DimensionsWrap>
                <div className='holder-slider-container'>
                  <VideoBox />
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    )
  }
}

export default Holder
