import React from "react";
import { PartnerBox, Footer } from "../../components";
import "./styles.css";

class Partners extends React.Component {

  render () {
    return (
      <div className="section">
        <div className="partners">
          <div className="partners-content">
            <div className="partners-title">
              <span className="card-title">Working with</span>
            </div>
            <div className="partner-box-wrapper">
              <div className="partner-box-row">
                <PartnerBox
                  image="hce.png"
                  description="hce"
                  width="92.3px"
                  height="92.3px"
                  sequence={1}
                />
                <PartnerBox
                  image="lespetitsas.png"
                  description="les petits as"
                  width="139x"
                  height="31px"
                  sequence={2}
                />
                <PartnerBox
                  image="adidas.png"
                  description="adidas"
                  width="89px"
                  height="59px"
                  sequence={3}
                />
                <PartnerBox
                  image="btf.png"
                  description="btf"
                  width="76px"
                  height="76px"
                  sequence={4}
                />
                <PartnerBox
                  image="fcbq.png"
                  description="fcbq"
                  width="123px"
                  height="127px"
                  sequence={5}
                />
              </div>
              <div className="partner-box-row">
              <PartnerBox
                  image="tennis.png"
                  description="tennis"
                  width="106.5px"
                  height="100px"
                  sequence={6}
                />
                <PartnerBox
                  image="adidaspadel.png"
                  description="adidaspadel"
                  width="97px"
                  height="97px"
                  sequence={7}
                />
                <PartnerBox
                  image="bmfa.png"
                  description="bmfa"
                  width="123px"
                  height="127px"
                  sequence={8}
                />
                <PartnerBox
                  image="davis.png"
                  description="davis"
                  width="175px"
                  height="102px"
                  sequence={9}
                />
                <PartnerBox
                  image="topfive.svg"
                  description="top five"
                  width="97.6px"
                  height="72.7px"
                  sequence={10}
                />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    )
  }
}

export default Partners;