import React from 'react'
import './mobileStyles.css'

class MobileDemonstration extends React.Component {
  render () {
    return (
      <div className='mobile-demonstration'>
        <img src={require('../../assets/imgs/phone2@2x.png')} className='mobile-demonstration-phone' />
        <div className='mobile-demonstration-info box-radius-right shadow'>
          <div className='mobile-demonstration-info-title mobile-title'>
            <span>Capture the moment after it happened</span>
          </div>
          <div className='mobile-demonstration-info-text mobile-text'>
            <span>Your smartphone camera has never been so clever before</span>
          </div>
          <img src={require('../../assets/svgs/arrows.svg')} alt='arrow' className='mobile-arrow' style={{ right: '25px' }} />
        </div>
        <img src={require('../../assets/svgs/socourt.svg')} alt='socourt' style={{
          position: 'relative',
          width: '90%',
          alignSelf: 'flex-end',
          marginTop: '85px',
          marginBottom: '10px'
        }} />
      </div>
    )
  }
}

export default MobileDemonstration
