import React from 'react'
import './styles.css'
// import { DimensionsWrap } from '../DimensionsWrap';
// import { _DimensionsWrap } from '../_DimensionsWrap';
import { DimensionsWrap, _DimensionsWrap, Emoji } from '../../components'

class Events extends React.Component {
  constructor (props) {
    super(props)
    this.toggleClass = this.toggleClass.bind(this)
    this.state = {
      active: true
    }
  }
  toggleClass () {
    const currentState = this.state.active
    this.setState({
      active: !currentState
    })
  };
  render () {
    return (
      <div className='section'>
        <div className='events'>
          <div className='events-content'>
            <ul>
              <li className={this.state.active ? 'active' : null} onClick={this.toggleClass}>
                <img src={require('../../assets/svgs/indicator.svg')} alt='indicator' className='translate-up' />
                <span className='card-title'>Features</span>
              </li>
              <li className={this.state.active ? null : 'active'} onClick={this.toggleClass}>
                <img src={require('../../assets/svgs/indicator.svg')} alt='indicator' className='translate-down' />
                <span className='card-title'>How It Works</span>
              </li>
            </ul>
            {this.state.active
              ? <div className='events-info-wrapper'>
                <DimensionsWrap heightProp={675 / 102} widthProp={(102 / 1920)} className='events-socourt-vertical'>
                  <img src={(require('../../assets/svgs/socourtvertical.svg'))} alt='socourt' min-width='100%' height='100%' />
                </DimensionsWrap>
                <div className='events-info-left'>
                  <DimensionsWrap heightProp={180 / 692} widthProp={(692 / 1920)} className='events-left-bubble shadow'>
                    <span className='card-text'>No limit of the video-replays that you can create during a game <Emoji symbol='🔢' label='numbers' />Slide to hi-lite as many time as good moments happen on the field</span>
                  </DimensionsWrap>
                  <DimensionsWrap heightProp={180 / 692} widthProp={(692 / 1920)} className='events-left-bubble shadow'>
                    <span className='card-text'>Dynamically select how many seconds back <Emoji symbol='⏪' label='rewind' />should the app save when you slide for a hi-lite - 10sec, 20sec, 40sec or 1 minute</span>
                  </DimensionsWrap>
                  <DimensionsWrap heightProp={180 / 692} widthProp={(692 / 1920)} className='events-left-bubble shadow'>
                    <DimensionsWrap heightProp={37.3 / 42.5} widthProp={42.5 / 1920} className='next-section-arrow'>
                      <img src={require('../../assets/svgs/arrows.svg')} alt='arrows' width='100%' height='100%' onClick={this.toggleClass} />
                    </DimensionsWrap>
                    <span className='card-text'>One video-replay captured from several different angles <Emoji symbol='📸📸📸' label='camera' />One creates a game, others join it - it’s so simple and powerful</span>
                  </DimensionsWrap>
                </div>
                <div className='events-info-right'>
                  <DimensionsWrap heightProp={180 / 820} widthProp={(820 / 1920)} className='events-right-bubble shadow'><span className='card-text'>Join a game and turn-off your camera <Emoji symbol='📴' label='off' />Now, sliding for a hi-lite will create video-replays from the other users’ cameras in this game</span></DimensionsWrap>
                  <DimensionsWrap heightProp={180 / 820} widthProp={(820 / 1920)} className='events-right-bubble shadow'><span className='card-text'>It’s all about showing to the world what you can create <Emoji symbol='🤩' label='star-struck' />And SoCourt makes it easy - share your best hi-lite or entire game to all leading social networks</span></DimensionsWrap>
                  <DimensionsWrap heightProp={180 / 820} widthProp={(820 / 1920)} className='events-right-bubble shadow'><span className='card-text'>Save the best of the best highlight in its relevant collection in your SoCourt profile <Emoji symbol='💾' label='floppy' /></span></DimensionsWrap>
                </div>
              </div>
              : <div className='events-info-wrapper'>
                <DimensionsWrap heightProp={675 / 102} widthProp={(102 / 1920)} className='events-socourt-vertical'>
                  <img src={(require('../../assets/svgs/socourtvertical.svg'))} alt='socourt' min-width='100%' height='100%' />
                </DimensionsWrap>
                <div>
                  <_DimensionsWrap heightProp={180 / 820} widthProp={(820 / 1920)} className='events-center-bubble dog shadow'>
                    <span className='card-text'>Position your phone with a good view to the game</span>
                  </_DimensionsWrap>
                  <_DimensionsWrap heightProp={180 / 820} widthProp={(820 / 1920)} className='events-center-bubble shadow'>
                    <span className='card-text'>AFTER something cool happened, make a hi-lite - by sliding the button on the phone, or with your smartwatch</span>
                  </_DimensionsWrap>
                  <_DimensionsWrap heightProp={180 / 820} widthProp={(820 / 1920)} className='events-center-bubble shadow'>
                    <a href='#Partners'>
                      <DimensionsWrap heightProp={37.3 / 42.5} widthProp={42.5 / 1920} className='next-section-arrow'>
                        <img src={require('../../assets/svgs/arrows.svg')} alt='arrows' width='100%' height='100%' />
                      </DimensionsWrap>
                    </a>
                    <span className='card-text'>Share to your friends and fans</span>
                  </_DimensionsWrap>
                </div>
              </div>}
          </div>
        </div>
      </div >
    )
  }
}

export default Events
