import React from 'react'
import { DimensionsWrap } from '../DimensionsWrap'
import './styles.css'

class Features extends React.Component {
  render () {
    return (
      <div className='section'>
        <div className='features'>
          <DimensionsWrap heightProp={675 / 102} widthProp={(102 / 1920)} className='features-socourt-vertical'>
            <img src={(require('../../assets/svgs/socourtvertical.svg'))} alt='socourt' width='100%' height='100%' />
          </DimensionsWrap>
          <div className='features-content'>
            <div className='features-content-title'>
              <span>{this.props.title || 'Features'}</span>
            </div>
            <div className='features-box-wrapper'>{this.props.children}</div>
          </div>
          <a href={`${this.props.goTo}`}>
            <DimensionsWrap heightProp={37.3 / 42.5} widthProp={42.5 / 1920} className='pricing next-section-arrow'>
              <img src={require('../../assets/svgs/arrows.svg')} alt='arrows' width='100%' height='100%' />
            </DimensionsWrap>
          </a>
        </div>
      </div>
    )
  }
}

export default Features
