import React from 'react'
import ReactFullPage from '@fullpage/react-fullpage'
import ReactPlayer from 'react-player'
import {
  Landing,
  Demonstration,
  Smartwatch,
  HowItWorks,
  Livestream,
  // Events,
  FeatureBox,
  Features,
  Partners
} from '../../components'
import './styles.css'
import './animations.css'

const anchors = [
  'Landing',
  'Demonstration',
  'How-It-Works',
  'Smartwatch',
  'Livestream',
  // 'Events',
  'Features-1',
  'Features-2',
  'Partners'
]

class Home extends React.Component {
  constructor (props) {
    super(props)

    this.preventTimeout = null
    this.handleAnimation = this.handleAnimation.bind(this)
    this.state = {
      seconds: null,
      animated: true,
      prevent: true
    }
  }
  componentWillUnmount () {
    clearTimeout(this.preventTimeout)
  }
  handleAnimation (bool) {
    // window.alert("wtf");
    this.setState({
      animated: bool
    })
  }
  render () {
    return (
      <ReactFullPage
        licenseKey={'2D5DBCF8-87FE4098-96CC2EFD-2CAB588F'}
        controlArrows={false}
        anchors={anchors}
        navigation
        // slidesNavigation
        // navigationTooltips={anchors}
        afterLoad={(origin, destination, direction) => {
          // console.log("afterLoad event", { origin, destination, direction });
          if (destination.index === 1) {
            this.setState({
              prevent: true,
              animated: true
            })
            let loadTime = new Date().getTime() / 1000
            this.setState({
              seconds: loadTime
            })
            // console.log(" we are at demonstration");
            // console.log("current seconds: ", this.state.seconds);
          }
          if (destination.index === 3) {
            this.setState({
              prevent: true,
              animated: true
            })
            let loadTime = new Date().getTime() / 1000
            this.setState({
              seconds: loadTime
            })
            // console.log(" we are at smartwatch");
            // console.log("current seconds: ", this.state.seconds);
          }
        }}
        onLeave={(origin, destination, direction) => {
          // console.log("onLeave event", { origin, destination, direction });
          if (origin.index === 1) {
            let leaveTime = new Date().getTime() / 1000
            if ((leaveTime - this.state.seconds) < 7.6 && this.state.prevent === true) {
              // console.log("PREVENT SCROLL");
              this.handleAnimation(false)
              this.loopTimeout = setTimeout(() => {
                this.setState({
                  prevent: false
                })
              }, 700)
              return false
            }
            this.setState({
              prevent: true,
              animated: true
            })
          }
          if (origin.index === 3) {
            let leaveTime = new Date().getTime() / 1000
            if ((leaveTime - this.state.seconds) < 9.1 && this.state.prevent === true) {
              // console.log("PREVENT SCROLL");
              this.handleAnimation(false)
              this.loopTimeout = setTimeout(() => {
                this.setState({
                  prevent: false
                })
              }, 700)
              return false
            }
            this.setState({
              prevent: true,
              animated: true
            })
          }
        }}

        render={({ state, fullpageApi }) => {
          // console.log("render prop change", state, fullpageApi);

          return (
            <div>
              <Landing />
              <Demonstration animated={this.state.animated} />
              <HowItWorks />
              <Smartwatch animated={this.state.animated} />
              <Livestream />
              {/* <Events /> */}
              <Features
                title='Features 1/2'
                goTo='#Features-2'>
                <FeatureBox
                  title='Replays'
                  text='Capture the hi-lites of the game you play or watch. Get instant access to the videos.'>
                  <ReactPlayer url={require('../../assets/videos/replays.mov')} light={require('../../assets/imgs/replaysposter.png')} muted playing controls width='100%' height='100%' />
                </FeatureBox>
                <FeatureBox
                  title='Different views'
                  text='Connect multiple phones to capture a single game. Enjoy the same hi-lite from all angles.'>
                  <ReactPlayer url={require('../../assets/videos/differentviews.mp4')} light={require('../../assets/imgs/differentviewsposter.png')} muted playing controls width='100%' height='100%' />
                </FeatureBox>
                <FeatureBox
                  title='Smartwatch friendly'
                  text='Comfortably create replays by pressing a button on the SoCourt app for Apple Watch and Android Wear.'>
                  <ReactPlayer url={require('../../assets/videos/smartwatch.mov')} light={require('../../assets/imgs/smartwatchposter.png')} muted playing controls width='100%' height='100%' />
                </FeatureBox>
                <FeatureBox
                  title='Remote control'
                  text='Make sure you join a game with at least one active camera. Now you can create replays from all other cameras by sliding the button on your phone.'>
                  <ReactPlayer url={require('../../assets/videos/remotecontrol.mp4')} light={require('../../assets/imgs/remoteposter.png')} muted playing controls width='100%' height='100%' />
                </FeatureBox>
              </Features>
              <Features
                title='Features 2/2'
                goTo='#Partners'>
                <FeatureBox
                  title='Adjustable length'
                  text='Select how many seconds back to start the replay. 20 seconds is by default.'>
                  <ReactPlayer url={require('../../assets/videos/length.mp4')} light={require('../../assets/imgs/lengthposter.png')} muted playing controls width='100%' height='100%' />
                </FeatureBox>
                <FeatureBox
                  title='Save'
                  text='Your game with all your highlights deserves to be securely saved in our cloud. Just post it and enjoy watching the videos whenever you want.'>
                  <ReactPlayer url={require('../../assets/videos/save.mp4')} light={require('../../assets/imgs/saveposter.png')} muted playing controls width='100%' height='100%' />
                </FeatureBox>
                <FeatureBox
                  title='Profile'
                  text='Scroll through all your games. Organize your favorites videos in different collections.'>
                  <ReactPlayer url={require('../../assets/videos/profile.mp4')} light={require('../../assets/imgs/profileposter.png')} muted playing controls width='100%' height='100%' />
                </FeatureBox>
                <FeatureBox
                  title='Share'
                  text='Share your whole game with all its highlights to any social network you prefer.'>
                  <ReactPlayer url={require('../../assets/videos/share.mp4')} light={require('../../assets/imgs/shareposter.png')} muted playing controls width='100%' height='100%' />
                </FeatureBox>
              </Features>
              <Partners />
            </div>
          )
        }}
      />
    )
  }
}
export default Home
