import React from 'react'
import { DimensionsWrap } from '../DimensionsWrap'
import './styles.css'

class IndividualsPricing extends React.Component {
  render () {
    return (
      <div className='section'>
        <div className='individuals-pricing'>
          <div className='individuals-pricing-content'>
            <div className='pricing-info'>
              <span>Your unique sport moments are just a swipe away</span>
            </div>
            <div className='individuals-pricing-card-container'>
              <DimensionsWrap heightProp={284 / 306} widthProp={306 / 1920} className='fan-1'>
                <img src={require('../../assets/svgs/malefan.svg')} alt='male fan' width='100%' height='100%' />
              </DimensionsWrap>
              <DimensionsWrap heightProp={543 / 406} widthProp={406 / 1970} className='pricing-card white shadow'>
                <div className='pricing-card-title shadow'>
                  <span>FREE</span>
                </div>
                <div className='pricing-card-text'>
                  <span>0€</span>
                  <ul>
                    <li>Video replays</li>
                    <li>Set hi-lite length</li>
                    <li>Add angles</li>
                    <li>Smartwatch friendly</li>
                  </ul>
                  <a target='_blank' rel='noopener noreferrer' href='https://itunes.apple.com/us/app/socourt/id1039455431'>                    <DimensionsWrap heightProp={55 / 240} widthProp={240 / 1920} className='pricing-btn join-v2'>
                    <span>Download Now</span>
                  </DimensionsWrap>
                  </a>
                </div>
              </DimensionsWrap>
              <DimensionsWrap heightProp={620 / 540} widthProp={540 / 1970} className='pricing-card blue shadow'>
                <div className='pricing-card-title shadow'>
                  <span>MONTHLY SUBSCRIPTION</span>
                </div>
                <div className='pricing-card-text'>
                  <span>4,95€</span>
                  <ul>
                    <li>Included free plan features</li>
                    <li>Save and Share</li>
                    <li>Collections</li>
                    <li>Live Stream</li>
                  </ul>
                  <a href='#'>
                    <DimensionsWrap heightProp={55 / 240} widthProp={240 / 1920} className='pricing-btn subscribe'>
                      <span>Coming soon</span>
                    </DimensionsWrap>
                  </a>
                </div>
              </DimensionsWrap>
            </div>
          </div>
          <a href='#Individuals-Features'>
            <DimensionsWrap heightProp={37.3 / 42.5} widthProp={42.5 / 1920} className='pricing next-section-arrow'>
              <img src={require('../../assets/svgs/arrows.svg')} alt='arrows' width='100%' height='100%' />
            </DimensionsWrap>
          </a>
        </div>
      </div>
    )
  }
}

export default IndividualsPricing
