import React from 'react'
// import ReactPlayer from "react-player";
import './styles.css'

class ExtendedDemonstration extends React.Component {
  render () {
    return (
      <div className='section'>
        <div className='extended-demonstration'>
          <div className='extended-demonstration-box shadow' />
          <div className='extended-demonstration-main'>
            <img src={(require('../../assets/svgs/socourtvertical.svg'))} alt='socourt' />
            <div className='extended-demonstration-info'>
              <div className='extended-demonstration-title'>
                <span className='card-title'>Add multiple view angles to your game</span>
              </div>
              <div className='extended-demonstration-text'>
                <span className='card-text'>No limit of the number of smartphones capturing the action</span>
              </div>
              <a href='#Smartwatch'>
                <img src={require('../../assets/svgs/arrows.svg')} alt='arrows' />
              </a>
            </div>
            <div className='extended-demonstration-phone'>
              {/* <ReactPlayer url={require("../../assets/videos/VID_20190207_163430.mp4")} width="100%" height="auto" playing loop muted/> */}
              <div className='extended-demonstration-video' />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ExtendedDemonstration
