import React from 'react'
import './styles.css'
import { DimensionsWrap } from '../DimensionsWrap'

class Livestream extends React.Component {
  render () {
    return (
      <div className='section'>
        <div className='livestream'>
          <div className='livestream-content'>
            <DimensionsWrap heightProp={675 / 102} widthProp={(102 / 1920)} className='livestream-socourt-vertical'>
              <img src={(require('../../assets/svgs/socourtvertical.svg'))} alt='socourt' width='100%' height='100%' />
            </DimensionsWrap>
            <DimensionsWrap heightProp={482 / 850} widthProp={(850 / 1920)} className='livestream-facebook'>
              <img src={require('../../assets/imgs/facebookstream@3x.png')} alt='facebook stream' width='100%' height='100%' />
              <DimensionsWrap heightProp={22 / 53} widthProp={(53 / 1920)} className='live-image'><img src={require('../../assets/imgs/LIVE@3x.png')} alt='live' width='100%' height='100%' /></DimensionsWrap>
              <DimensionsWrap heightProp={58 / 58} widthProp={(58 / 1920)} className='heart-image'><img src={require('../../assets/svgs/love.svg')} alt='heart reaction' width='100%' height='100%' /></DimensionsWrap>
              <DimensionsWrap heightProp={58 / 58} widthProp={(58 / 1920)} className='like-image'><img src={require('../../assets/imgs/like@2x.png')} alt='like reaction' width='100%' height='100%' /></DimensionsWrap>
            </DimensionsWrap>
            <DimensionsWrap heightProp={902 / 550} widthProp={(510 / 1920)} className='livestream-phone'>
              <img src={require('../../assets/imgs/phonemiddle.jpeg')} alt='facebook stream' width='100%' height='100%' />
            </DimensionsWrap>
            <DimensionsWrap heightProp={482 / 850} widthProp={(850 / 1920)} className='livestream-youtube'>
              <img src={require('../../assets/imgs/youtube@3x.png')} alt='youtube stream' width='100%' height='100%' />
              <DimensionsWrap heightProp={22 / 53} widthProp={(53 / 1920)} className='live-image'><img src={require('../../assets/imgs/LIVE@3x.png')} alt='live' width='100%' height='100%' /></DimensionsWrap>
            </DimensionsWrap>
          </div>
          <DimensionsWrap heightProp={304 / 784} widthProp={784 / 1920} className='livestream-info shadow'>
            <div className='livestream-title'>
              <span className='card-title'>Livestream with ease</span>
            </div>
            <div className='livestream-text'>
              <span className='card-text'>Your entire game is worth watching? Start streaming live and keep it recorded for a post-analysis</span>
            </div>
            <a href='#Features-1'>
              <DimensionsWrap heightProp={37.3 / 42.5} widthProp={42.5 / 1920} className='next-section-arrow'>
                <img src={require('../../assets/svgs/arrows.svg')} alt='arrows' width='100%' height='100%' />
              </DimensionsWrap>
            </a>
          </DimensionsWrap>
        </div>
      </div>
    )
  }
}

export default Livestream
