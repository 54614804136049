import React from 'react'
import { ReactComponent as Facebook } from '../../assets/svgs/facebookcircle.svg'
import { ReactComponent as YouTube } from '../../assets/svgs/youtubecircle.svg'
import './mobileStyles.css'

class MobileFooter extends React.Component {
  render () {
    return (
      <div className='mobile-footer'>
        <div className='mobile-footer-address-wrapper'>
          <address className='mobile-footer-address'>
            <span>address in Sofia: Makedonia 1 sq., KNSB building, fl. 18</span>
            <span>e-mail: <a href='mailto:team@thesocourt.com'>team@thesocourt.com</a></span>
          </address>
          <address className='mobile-footer-address'>
            <span>address in Berlin: Eldenaer Straße 35, 10247 Berlin, Germany</span>
            <span>e-mail: <a href='mailto:team@thesocourt.com'>team@thesocourt.com</a></span>
          </address>
        </div>
        <div className='mobile-footer-social-media-links'>
          <a target='_blank' rel='noopener noreferrer' href='https://facebook.com/thesocourt/'><Facebook className='mobile-social-link' /></a>
          <a target='_blank' rel='noopener noreferrer' href='https://www.youtube.com/channel/UCOJzMZ6JSzBxPd2CNbsnVsQ'><YouTube className='mobile-social-link' /></a>
        </div>
      </div>
    )
  }
}

export default MobileFooter
