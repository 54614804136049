import React from 'react'
import './mobileStyles.css'

class MobileHowItWorks extends React.Component {
  render () {
    return (
      <div className='mobile-how-it-works'>

        <div className='mobile-how-it-works-position'>
          <img src={require('../../assets/svgs/basketball1.svg')} alt='basketball start' />
          <div className='mobile-how-it-works-title'>
            <p>How it works</p>
          </div>
          <div className='mobile-how-it-works-text-container first'>
            <p>Position your phone's camera to watch the game</p>
            <div className='mobile-hiw-border-skew' />
          </div>
          <div className='mobile-how-it-works-text-container second'>
            <p>Your camera is now recording</p>
            <div className='mobile-hiw-border-skew' />
          </div>
        </div>
        <div className='mobile-how-it-works-slide'>
          <img src={require('../../assets/svgs/basketball2.svg')} alt='basketball slide' />
          <div className='mobile-how-it-works-text-container'>
            <p>AFTER a cool moment happened, slide the button</p>
            <div className='mobile-hiw-border-skew' />
          </div>
          <div className='mobile-hiw-hi-lite-button' />
        </div>
        <div className='mobile-how-it-works-video'>
          <img src={require('../../assets/gifs/hiw-2.gif')} alt='how it works gif' width='100%' height='100%' />
        </div>
      </div>
    )
  }
}

export default MobileHowItWorks
