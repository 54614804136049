import React from 'react'
import { ReactComponent as Facebook } from '../../assets/svgs/facebookcircle.svg'
import { ReactComponent as YouTube } from '../../assets/svgs/youtubecircle.svg'
import './styles.css'

class Footer extends React.Component {
  render () {
    return (
      <div className='footer'>
        <div className='footer-content'>
          <div className='footer-address-wrapper'>
            <address className='footer-address'>
              <span>address in Sofia: Makedonia 1 sq., KNSB building, fl. 18</span>
              <span>e-mail: <a href='mailto:team@thesocourt.com'>team@thesocourt.com</a></span>
            </address>
            <address className='footer-address'>
              <span>address in Berlin: Eldenaer Straße 35, 10247 Berlin, Germany</span>
              <span>e-mail: <a href='mailto:team@thesocourt.com'>team@thesocourt.com</a></span>
            </address>
          </div>
          <div className='footer-social-media-links'>
            <a target='_blank' rel='noopener noreferrer' href='https://facebook.com/thesocourt/'><Facebook className='social-link' /></a>
            <a target='_blank' rel='noopener noreferrer' href='https://www.youtube.com/channel/UCOJzMZ6JSzBxPd2CNbsnVsQ'><YouTube className='social-link' /></a>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer
