import React from 'react'
import { Emoji } from '../../components'
import './mobileStyles.css'

class MobileEvents extends React.Component {
  render () {
    return (
      <div className='mobile-events'>
        <div className='mobile-events-title-features mobile-title'><span>Features</span></div>
        <div className='mobile-events-info mobile-text shadow'>
          <span>
            No limit of the video-replays that you can create during a game <Emoji symbol='🔢' label='numbers' /> Slide to hi-lite as many time as good moments happen on the field
          </span>
        </div>
        <div className='mobile-events-info mobile-text shadow'>
          <span>
            Join a game and turn-off your camera <Emoji symbol='📴' label='off' /> Now, sliding for a hi-lite will create video-replays from the other users’ cameras in this game
          </span>
        </div>
        <div className='mobile-events-info mobile-text shadow'>
          <span>
            Dynamically select how many seconds back <Emoji symbol='⏪' label='rewind' /> should the app save when you slide for a hi-lite - 10sec, 20sec, 40sec or 1 minute
          </span>
        </div>
        <div className='mobile-events-info mobile-text shadow'>
          <span>
            It’s all about showing to the world what you can create <Emoji symbol='🤩' label='star-struck' /> And SoCourt makes it easy - share your best hi-lite or entire game to all leading social networks
          </span>
        </div>
        <div className='mobile-events-info mobile-text shadow'>
          <span>
            One video-replay captured from several different angles <Emoji symbol='📸📸📸' label='camera' /> One creates a game, others join it - it’s so simple and powerful
          </span>
        </div>
        <div className='mobile-events-info mobile-text shadow'>
          <span>
            Save the best of the best highlight in its relevant collection in your SoCourt profile <Emoji symbol='💾' label='floppy' />
          </span>
        </div>
        <div className='mobile-events-title-how mobile-title'><span>How It Works</span></div>
        <div className='mobile-events-info mobile-text shadow'>
          <span>
            Position your phone with a good view to the game
          </span>
        </div>
        <div className='mobile-events-info mobile-text shadow'>
          <span>
            AFTER something cool happened, make a hi-lite - by sliding the button on the phone, or with your smartwatch
          </span>
        </div>
        <div className='mobile-events-info mobile-text shadow'>
          <span>
            Share to your friends and fans
          </span>
        </div>
      </div >
    )
  }
}

export default MobileEvents
