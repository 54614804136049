import React from 'react'
import './styles.css'
import { ReactComponent as AppStore } from '../../assets/svgs/app store.svg'
import { ReactComponent as GooglePlay } from '../../assets/svgs/google play.svg'
import { DimensionsWrap } from '../DimensionsWrap'

class Landing extends React.Component {
  render () {
    return (
      <div className='section'>
        <div className='landing'>
          <DimensionsWrap heightProp={1236 / 1236} widthProp={(1236 / 2048)} className='ellipse-top'>
            <img src={require('../../assets/svgs/ellipse.svg')} alt='ellipse' width='100%' height='100%' />
          </DimensionsWrap>
          <DimensionsWrap heightProp={995 / 995} widthProp={(995 / 2048)} className='ellipse-bottom'>
            <img src={require('../../assets/svgs/ellipse.svg')} alt='ellipse' width='100%' height='100%' />
          </DimensionsWrap>
          <div className='landing-content'>
            <div className='landing-info'>
              <div className='landing-title'>
                <span>Only the best video of your game</span>
              </div>
              <div className='landing-text'>
                <span>No additional hardware required - all you need is your smartphone</span>
              </div>
              <div className='app-links'>
                <a target='_blank' rel='noopener noreferrer' href='https://itunes.apple.com/us/app/socourt/id1039455431'><AppStore className='applink' /></a>
                <a target='_blank' rel='noopener noreferrer' href='https://play.google.com/store/apps/details?id=com.yannik.socourt'><GooglePlay className='applink' /></a>
              </div>
            </div>
            <DimensionsWrap heightProp={1070 / 570} widthProp={(570 / 1920)} className='landing-phone'>
              <video src={require('../../assets/videos/landing.mov')} poster={require('../../assets/imgs/landingposter.png')} autoPlay loop muted data-keepplaying />
            </DimensionsWrap>
          </div>
          <div className='white-wave' />
        </div>
      </div>
    )
  }
}

export default Landing
