import React from 'react'
import { ReactComponent as AppStore } from '../../assets/svgs/app store.svg'
import { ReactComponent as GooglePlay } from '../../assets/svgs/google play.svg'
import './mobileStyles.css'

class MobileLanding extends React.Component {
  render () {
    return (
      <div className='mobile-landing'>
        <div className='mobile-landing-info'>
          <div className='mobile-landing-title'>
            <span display='block'>Only the best</span>
            <span display='block'>video of your game</span>
          </div>
          <div className='mobile-landing-text'>
            <span display='block'>No additional hardware</span>
            <span display='block'>required - all you need is</span>
            <span display='block'>your smartphone</span>
          </div>
        </div>
        <div className='mobile-landing-wrap'>
          <div className='mobile-landing-app-links'>
            <a target='_blank' rel='noopener noreferrer' href='https://itunes.apple.com/us/app/socourt/id1039455431'><AppStore className='mobile-applink' /></a>
            <a target='_blank' rel='noopener noreferrer' href='https://play.google.com/store/apps/details?id=com.yannik.socourt'><GooglePlay className='mobile-applink' /></a>
          </div>
          <div className='mobile-landing-phone'>
            <img src={require('../../assets/imgs/phone1@2x.png')} alt='phone' />
          </div>
        </div>
        <img src={require('../../assets/svgs/ellipse.svg')} alt='ellipse' className='mobile-ellipse' />
        <img src={require('../../assets/svgs/wave.svg')} alt='white wave' className='mobile-white-wave' />
      </div>
    )
  }
}

export default MobileLanding
