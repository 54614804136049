import React from 'react'
import ReactFullPage from '@fullpage/react-fullpage'
import {
  FederationsPricing,
  Features,
  FeatureBox,
  Holder
} from '../../components'
// import TestComponent from "../../components/TestComponent/TestComponent.js";
import './styles.css'

const anchors = [
  'Federations-Pricing',
  'Federations-Features',
  'Federations-Features-Extended',
  'Federations-Holder']

const Federations = () => (
  <ReactFullPage
    licenseKey={'2D5DBCF8-87FE4098-96CC2EFD-2CAB588F'}
    controlArrows={false}
    anchors={anchors}
    navigation
    // slidesNavigation
    // navigationTooltips={anchors}
    // onLeave={(origin, destination, direction) => {
    //   console.log("onLeave event", { origin, destination, direction });
    // }}

    render={({ state, fullpageApi }) => {
      // console.log("render prop change", state, fullpageApi);

      return (
        <div>
          <FederationsPricing />
          <Features goTo='#Federations-Features-Extended'>
            <FeatureBox
              title='Live stream'
              text='Live stream the games of your event to multiple platforms. Embed scoreboard to the stream for maximum fan engagement.'>
              <video src={require('../../assets/videos/VID_20190207_163430.mp4')} autoPlay loop muted data-keepplaying width='100%' height='100%' />
            </FeatureBox>
            <FeatureBox
              title='Live score'
              text='Easily follow and update the score of each match at your event. Combine with live streaming at any time, by embedding a scoreboard into the stream.'>
              <video src={require('../../assets/videos/VID_20190207_163430.mp4')} autoPlay loop muted data-keepplaying width='100%' height='100%' />
            </FeatureBox>
            <FeatureBox
              title='Video Replays'
              text='Capture and share instant hi-lites from the games at your event. Get automatic replays after updates in the live score. Share manually or automatically to multiple platforms.'>
              <video src={require('../../assets/videos/VID_20190207_163430.mp4')} autoPlay loop muted data-keepplaying width='100%' height='100%' />
            </FeatureBox>
            <FeatureBox
              title='Compilations'
              text='Create compilations with your favourite video replays from the event, or get automatic suggestions by SoCourt. Share manually or automatically to multiple platforms.'>
              <video src={require('../../assets/videos/VID_20190207_163430.mp4')} autoPlay loop muted data-keepplaying width='100%' height='100%' />
            </FeatureBox>
          </Features>
          <Features goTo='#Federations-Holder'>
            <FeatureBox
              title='Commercials'
              text='Integrate logo and video ads of your event or sponsors in all generated streams and hi-lites. Watch them go viral among the fans.'>
              <video src={require('../../assets/videos/VID_20190207_163430.mp4')} autoPlay loop muted data-keepplaying width='100%' height='100%' />
            </FeatureBox>
            <FeatureBox
              title='Empower your audience'
              text='Further engage with your fans by letting them create their own hi-lites, no matter if they are spectating your event on-site or watching it over a stream.'>
              <video src={require('../../assets/videos/VID_20190207_163430.mp4')} autoPlay loop muted data-keepplaying width='100%' height='100%' />
            </FeatureBox>
            <FeatureBox
              title='Restrict access'
              text='Enable restricted access to the video content streamed in SoCourt. Activate pay-per-view or event code features for the purpose to entertain only your biggest fans.'>
              <video src={require('../../assets/videos/VID_20190207_163430.mp4')} autoPlay loop muted data-keepplaying width='100%' height='100%' />
            </FeatureBox>
          </Features>
          <Holder cardHeight={535 / 569} cardWidth={569 / 1920} />
        </div>
      )
    }}
  />
)

export default Federations
