import React from 'react'
import Draggable from 'react-draggable'
import './styles.css'

class HiLiteSlider extends React.Component {
  constructor (props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
    this.handleDrag = this.handleDrag.bind(this)
    this.onStop = this.onStop.bind(this)
    this.onStart = this.onStart.bind(this)
    this.onControlledDragStop = this.onControlledDragStop.bind(this)
    this.onControlledDrag = this.onControlledDrag.bind(this)
    this.onStopPropFix = this.onStopPropFix.bind(this)

    this.state = {
      clicked: false,
      dragged: false,
      deltaPosition: {
        x: 0,
        y: 0
      },
      controlledPosition: {
        x: 0,
        y: 0
      }
    }
  }
  componentDidMount () {
    this.props.onRef(this)
  }
  componentWillUnmount () {
    this.props.onRef(undefined)
  }
  method () {
    // console.log("call child method()");
    window.alert('do stuff')
  }
  handleClick () {
    this.props.action()

    this.setState({ clicked: true })

    setTimeout(function () {
      this.setState({ clicked: false })
    }.bind(this), 2000)
  };
  onStart () {
  };
  onStop () {
    (!this.state.dragged && !this.state.clicked) && this.onControlledDragStop()
  };
  onStopPropFix () {
    (!this.state.dragged && !this.state.clicked) && this.onControlledDragStop()
  };
  handleDrag (e, ui) {
    const { x, y } = this.state.deltaPosition;
    (!this.state.dragged && !this.state.clicked) && this.setState({
      deltaPosition: {
        x: x + ui.deltaX,
        y: y + ui.deltaY
      }
    })
  };
  onControlledDrag (e, position) {
    const { x, y } = position
    this.setState({ controlledPosition: { x, y } })
  };
  onControlledDragStop (e, position) {
    if (this.state.deltaPosition.x <= -235) {
      this.props.action()
      this.setState({ dragged: true })
      this.onControlledDrag(e, { x: -255, y: 0 })
      this.setState({
        deltaPosition: {
          x: -255,
          y: 0
        }
      })

      setTimeout(function () {
        this.setState({ dragged: false })
        this.onControlledDrag(e, { x: 0, y: 0 })
        this.setState({
          deltaPosition: {
            x: 0,
            y: 0
          }
        })
      }.bind(this), 1950)
    } else if (this.state.deltaPosition.x !== 0) {
      this.setState({
        deltaPosition: {
          x: 0,
          y: 0
        }
      })
    } else {
      this.handleClick()
    }
  };

  render () {
    const { controlledPosition } = this.state
    return (
      <div className='slidecontainer'>
        <Draggable
          bounds={{ top: 0, left: -255, right: 0, bottom: 0 }}
          onDrag={this.handleDrag}
          position={controlledPosition}
          onStop={this.onStopPropFix}
        >
          <div
            className={this.state.clicked ? 'slider clicked' : (this.state.dragged ? 'slider dragged' : 'slider')}
            // onClick={this.state.clicked ? () => { } : this.handleClick}
            style={{ fontSize: '8px' }} />
        </Draggable>
        <span className={this.state.clicked ? 'slider-text clicked' : 'slider-text'}
          style={{ opacity: (this.state.deltaPosition.x >= -20) ? 1 : (this.state.deltaPosition.x <= -100) ? 0 : (1 / this.state.deltaPosition.x * -12) }}
        >slide to hi-lite</span>
      </div>
    )
  }
}

export default HiLiteSlider
