import React, { Component } from 'react'
import LoadingScreen from 'react-loading-screen'
import { Header } from './components'

class App extends Component {
  constructor () {
    super()
    const INITIAL_STATE = window.innerWidth
    this.state = {
      desktop: INITIAL_STATE > 1025,
      loading: true
    }
  }
  updateScreen () {
    const width = window.innerWidth
    if (width >= 1025 && this.state.desktop === false) {
      this.setState({
        desktop: true
      })
      setTimeout(function () {
        window.location.reload()
      })
    } else if (width < 1025 && this.state.desktop === true) {
      this.setState({
        desktop: false
      })
      setTimeout(function () {
        window.location.reload()
      })
    }
  }
  componentDidMount () {
    setTimeout(() =>
      this.setState({ loading: false })
    , 1500)

    this.updateScreen()
    window.addEventListener('resize', this.updateScreen.bind(this))
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.updateScreen.bind(this))
  }
  render () {
    return (
      <div className='App'>
        <LoadingScreen
          loading={this.state.loading}
          bgColor='#f1f1f1'
          spinnerColor='#13293F'
          textColor='#13293F'
          logoSrc={require('./assets/imgs/socourticon.png')}
          text='SoCourt'>
          <Header />
        </LoadingScreen>
      </div>
    )
  }
}

export default App
