import React from 'react'
import { PartnerBox, Footer } from '../../components'
import './mobileStyles.css'

class MobilePartners extends React.Component {
  render () {
    return (
      <div className='mobile-partners'>
        <div className='mobile-partners-title mobile-title'><span>Working With</span></div>
        <div className='mobile-partners-box-wrapper'>
          <PartnerBox
            image='hce.png'
            description='hce'
            width='83.5px'
            height='83.5px'
            sequence={1}
          />
          <PartnerBox
            image='lespetitsas.png'
            description='les petits as'
            width='125.7px'
            height='28px'
            sequence={2}
          />
          <PartnerBox
            image='adidas.png'
            description='adidas'
            width='75px'
            height='50.7px'
            sequence={3}
          />
          <PartnerBox
            image='btf.png'
            description='btf'
            width='65.3px'
            height='65.3px'
            sequence={4}
          />
          <PartnerBox
            image='fcbq.png'
            description='fcbq'
            width='105.7px'
            height='109.1px'
            sequence={5}
          />
          <PartnerBox
            image='tennis.png'
            description='tennis'
            width='96.3px'
            height='89.6px'
            sequence={6}
          />
          <PartnerBox
            image='adidaspadel.png'
            description='adidaspadel'
            width='85px'
            height='85px'
            sequence={7}
          />
          <PartnerBox
            image='bmfa.png'
            description='bmfa'
            width='105.7px'
            height='109.1px'
            sequence={8}
          />
          <PartnerBox
            image='davis.png'
            description='davis'
            width='150.4px'
            height='87.6px'
            sequence={9}
          />
          <PartnerBox
            image='topfive.svg'
            description='top five'
            width='83.9px'
            height='62.5px'
            sequence={10}
          />
        </div>
        <Footer />
      </div>
    )
  }
}

export default MobilePartners
