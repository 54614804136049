import React from 'react'
import {
  Landing,
  Demonstration,
  Smartwatch,
  Livestream,
  // Events,
  Features,
  FeatureBox,
  Partners,
  HowItWorks
} from '../../components'

const MobileHome = () => {
  return (
    <div>
      <Landing />
      <Demonstration />
      <HowItWorks />
      <Smartwatch />
      <Livestream />
      <Features>
        <FeatureBox
          title='Replays'
          text='Capture the hi-lites of the game you play or watch. Get instant access to the videos.'>
          <video src={require('../../assets/videos/replays.mov')} muted controls poster={require('../../assets/imgs/replaysposter.png')} width='100%' height='100%' />
        </FeatureBox>
        <FeatureBox
          title='Different views'
          text='Connect multiple phones to capture a single game. Enjoy the same hi-lite from all angles.'>
          <video src={require('../../assets/videos/differentviews.mp4')} muted controls poster={require('../../assets/imgs/differentviewsposter.png')} width='100%' height='100%' />
        </FeatureBox>
        <FeatureBox
          title='Smartwatch friendly'
          text='Comfortably create replays by pressing a button on the SoCourt app for Apple Watch and Android Wear.'>
          <video src={require('../../assets/videos/smartwatch.mov')} muted controls poster={require('../../assets/imgs/smartwatchposter.png')} width='100%' height='100%' />
        </FeatureBox>
        <FeatureBox
          title='Remote control'
          text='Make sure you join a game with at least one active camera. Now you can create replays from all other cameras by sliding the button on your phone.'>
          <video src={require('../../assets/videos/remotecontrol.mp4')} muted controls poster={require('../../assets/imgs/remoteposter.png')} width='100%' height='100%' />
        </FeatureBox>
        <FeatureBox
          title='Adjustable length'
          text='Select how many seconds back to start the replay. 20 seconds is by default.'>
          <video src={require('../../assets/videos/length.mp4')} muted controls poster={require('../../assets/imgs/lengthposter.png')} width='100%' height='100%' />
        </FeatureBox>
        <FeatureBox
          title='Save'
          text='Your game with all your highlights deserves to be securely saved in our cloud. Just post it and enjoy watching the videos whenever you want.'>
          <video src={require('../../assets/videos/save.mp4')} muted controls poster={require('../../assets/imgs/saveposter.png')} width='100%' height='100%' />
        </FeatureBox>
        <FeatureBox
          title='Profile'
          text='Scroll through all your games. Organize your favorites videos in different collections.'>
          <video src={require('../../assets/videos/profile.mp4')} muted controls poster={require('../../assets/imgs/profileposter.png')} width='100%' height='100%' />
        </FeatureBox>
        <FeatureBox
          title='Share'
          text='Share your whole game with all its highlights to any social network you prefer.'>
          <video src={require('../../assets/videos/share.mp4')} muted controls poster={require('../../assets/imgs/shareposter.png')} width='100%' height='100%' />
        </FeatureBox>
      </Features>
      <Partners />
    </div>
  )
}

export default MobileHome
