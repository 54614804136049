import React from 'react'
import './mobileStyles.css'

class MobileFederationsPricing extends React.Component {
  render () {
    return (
      <div className='mobile-federations-pricing'>
        <div className='mobile-individuals-pricing-background' />
        <div className='mobile-federations-pricing-info'>
          <div className='mobile-federations-pricing-title'>
            <span>Your mobile real-time video broadcaster</span>
          </div>
          <div className='mobile-federations-pricing-text'>
            <span>All-in-one platform to create & share engaging content from your games</span>
          </div>
        </div>
        <div className='mobile-individuals-pricing-card-container'>
          <div className='mobile-card-image-wrap'>
            <div className='mobile-individuals-pricing-card white shadow'>
              <div className='mobile-individuals-pricing-card-title shadow'>
                <span>MONTHLY SUBSCRIPTION</span>
              </div>
              <div className='mobile-individuals-pricing-card-text'>
                <span>4,95€</span>
                <ul>
                  <li>Live stream</li>
                  <li>Live score</li>
                  <li>Video replays</li>
                  <li>Compilations</li>
                  <li>Commercials</li>
                  <li>Empower your audience</li>
                  <li>Restrict access</li>
                </ul>
                <a href='#'>
                  <div className='mobile-pricing-btn subscribe'>
                    Subcribe
                  </div>
                </a>
              </div>
            </div>
            <img src={require('../../assets/svgs/announcer.svg')} alt='announcer' className='mobile-federations-pricing-image' />
          </div>
        </div>
      </div>
    )
  }
}

export default MobileFederationsPricing
