import React from 'react'
import './styles.css'
import { VideoBox } from '../../components'

class TestComponent extends React.Component {
  render () {
    return (
      <div className='section'>
        <div className='view'>
          <VideoBox />
        </div>
      </div>
    )
  }
}
export default TestComponent
