import React from 'react'
import './styles.css'

class PartnerBox extends React.Component {
  render () {
    const image = this.props.image
    const description = this.props.description
    const width = this.props.width
    const height = this.props.height
    const sequence = this.props.sequence * 0.1

    return (
      <div
        className='partner-box shadow'
        style={{
          animationDelay: `${1 + sequence}s`,
          animationFillMode: 'both'
        }}>
        <img
          src={require(`../../assets/partners/${image}`)}
          alt={description}
          width={width}
          height={height} />
      </div>
    )
  }
}

export default PartnerBox
