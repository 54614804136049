import React from 'react'
import './mobileStyles.css'

class MobileClubsPricing extends React.Component {
  render () {
    return (
      <div className='mobile-clubs-pricing'>
        <div className='mobile-individuals-pricing-background' />
        <div className='mobile-clubs-pricing-info'>
          <div className='mobile-clubs-pricing-title'>
            <span>Provide immediate satisfaction to your players and coaches.</span>
          </div>
          <div className='mobile-clubs-pricing-text'>
            <span>Let your club and its sponsors engage with a highly targeted audience of sports lovers.</span>
          </div>
        </div>
        <div className='mobile-individuals-pricing-card-container'>
          <div className='mobile-card-image-wrap'>
            <div className='mobile-individuals-pricing-card white shadow'>
              <div className='mobile-individuals-pricing-card-title shadow'>
                <span>YEARLY SUBSCRIPTION</span>
              </div>
              <div className='mobile-individuals-pricing-card-text'>
                <span>4,95€</span>
                <ul>
                  <li>Get hi-lites from your fields</li>
                  <li>Video replays</li>
                  <li>Commercials</li>
                  <li>Included phone holder and info material</li>
                </ul>
                <a href='#'>
                  <div className='mobile-pricing-btn subscribe'>
                    Subcribe
                  </div>
                </a>
              </div>
            </div>
            <img src={require('../../assets/svgs/footballer.svg')} alt='sport fans' className='mobile-clubs-pricing-image' />
          </div>
        </div>
      </div>
    )
  }
}

export default MobileClubsPricing
