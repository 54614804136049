import React from 'react'
import ReactFullPage from '@fullpage/react-fullpage'
import './styles.css'

import TestComponent from '../../components/TestComponent/TestComponent.js'

const anchors = [
  'One',
  'Two',
  'Three',
  'Four',
  'Five'
]

const LogIn = () => (
  <ReactFullPage
    licenseKey={'2D5DBCF8-87FE4098-96CC2EFD-2CAB588F'}
    controlArrows={false}
    anchors={anchors}
    navigation
    // slidesNavigation
    navigationTooltips={anchors}
    // onLeave={(origin, destination, direction) => {
    //   console.log("onLeave event", { origin, destination, direction });
    // }}

    render={({ state, fullpageApi }) => {
      // console.log("render prop change", state, fullpageApi);

      return (
        <div>
          <TestComponent />
          <TestComponent />
          <TestComponent />
        </div>
      )
    }}
  />
)

export default LogIn
