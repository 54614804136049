import React from 'react'

class _DimensionsWrap extends React.PureComponent {
  constructor (props) {
    super(props)
    const widthProp = this.props.widthProp
    const heightProp = this.props.heightProp
    this.state = {
      widthProp: widthProp,
      width: window.innerWidth * widthProp,
      height: window.innerWidth * widthProp * heightProp,
      proportion: heightProp
    }
  }

  updateDimensions () {
    const width = window.innerWidth * this.state.widthProp
    const height = width * this.state.proportion
    this.setState({ width, height })
  }

  componentDidMount () {
    this.updateDimensions()
    window.addEventListener('resize', this.updateDimensions.bind(this))
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.updateDimensions.bind(this))
  }

  render () {
    return (
      <div className={this.props.className || ''} style={{
        width: `${this.state.width}px`,
        height: `${this.state.height}px`
      }}>
        {this.props.children}
      </div>
    )
  }
}

export default _DimensionsWrap
