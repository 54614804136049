import React from 'react'
import { DimensionsWrap } from '../DimensionsWrap'
import './styles.css'

class ClubsPricing extends React.Component {
  render () {
    return (
      <div className='section'>
        <div className='clubs-pricing'>
          <div className='clubs-pricing-content'>
            <div className='clubs-pricing-info'>
              <div className='clubs-pricing-info-title'>
                <span>Provide immediate satisfaction to your players and coaches.</span>
              </div>
              <div className='clubs-pricing-info-text'>
                <span>Let your club and its sponsors engage with a highly targeted audience of sport lovers.</span>
              </div>
            </div>
            <div className='clubs-pricing-card-container'>
              <DimensionsWrap heightProp={266 / 250} widthProp={250 / 1920} className='clubs-pricing-footballer'>
                <img src={require('../../assets/svgs/footballer.svg')} alt='footballer' width='100%' height='100%' />
              </DimensionsWrap>
              <DimensionsWrap heightProp={694 / 540} widthProp={540 / 1970} className='pricing-card white shadow'>
                <div className='pricing-card-title shadow'>
                  <span>YEARLY SUBSCRIPTION</span>
                </div>
                <div className='pricing-card-text'>
                  <span>4,95€</span>
                  <ul>
                    <li>Get hi-lites from your fields</li>
                    <li>Video replays</li>
                    <li>Commercials</li>
                    <li>Included phone holder and info material</li>
                  </ul>
                  <a href='#'>
                    <DimensionsWrap heightProp={55 / 240} widthProp={240 / 1920} className='pricing-btn subscribe'>
                      Subscribe
                    </DimensionsWrap>
                  </a>
                </div>
              </DimensionsWrap>
            </div>
          </div>
          <a href='#Clubs-Features'>
            <DimensionsWrap heightProp={37.3 / 42.5} widthProp={42.5 / 1920} className='pricing next-section-arrow'>
              <img src={require('../../assets/svgs/arrows.svg')} alt='arrows' width='100%' height='100%' />
            </DimensionsWrap>
          </a>
        </div>
      </div>
    )
  }
}

export default ClubsPricing
