import React from 'react'
import { DimensionsWrap } from '../DimensionsWrap'
import './styles.css'

class FeatureBox extends React.Component {
  render () {
    return (
      <div className='feature-box'>
        <DimensionsWrap heightProp={370 / 210} widthProp={210 / 1920} className='feature-box-visual'>
          {this.props.children}
        </DimensionsWrap>
        <div className='feature-box-title'>
          <span>{this.props.title}</span>
        </div>
        <div className='feature-box-text'>
          <span>{this.props.text}</span>
        </div>
      </div>
    )
  }
}

export default FeatureBox
