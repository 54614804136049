import React from 'react'
import './mobileStyles.css'

class MobileSmartwatch extends React.Component {
  render () {
    return (
      <div className='mobile-smartwatch'>
        <div className='mobile-smartwatch-info box-radius-left shadow'>
          <div className='mobile-smartwatch-info-title mobile-title'>
            <span>Command SoCourt from your smartwatch</span>
          </div>
          <div className='mobile-smartwatch-info-text mobile-text'>
            <span>Use the smartwatch app while playing. Or slide the button on your smartphone, if it is next to you</span>
          </div>
          {/* <img src={require("../../assets/svgs/arrows.svg")} alt="arrow" className="mobile-arrow" style={{ right: "25px" }} /> */}
        </div>
        <img src={require('../../assets/imgs/smartwatchhand.png')} alt='smartwatch hand' style={{
          position: 'absolute',
          maxWidth: '900px',
          width: '100%',
          height: 'auto',
          bottom: 0,
          left: 0
        }} />
      </div >
    )
  }
}

export default MobileSmartwatch
