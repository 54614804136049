import React from "react";
import { HiLiteSlider } from "../HiLiteSlider";
import { DimensionsWrap } from "../DimensionsWrap";
import VisibilitySensor from "react-visibility-sensor"
import "./styles.css";

class Demonstration extends React.Component {
  constructor (props) {
    super(props)

    this.handleTrigger = this.handleTrigger.bind(this);
    this.handleVisible = this.handleVisible.bind(this);
    this.triggerTimeout = null;
    this.loopTimeout = null;
    this.state = {
      trigger: false,
      mounted: false
    };
  }
  componentDidMount () {
    this.setState({ mounted: true })
  }
  componentWillUnmount () {
    clearTimeout(this.triggerTimeout);
    clearTimeout(this.loopTimeout);
    this.setState({ mounted: false })
  }
  handleTrigger () {
    this.setState({ trigger: true });

    setTimeout(function () {
      this.setState({ trigger: false })
    }.bind(this), 2000)
  };

  timer = () => {
    this.child.handleClick();
    this.loopTimeout = setTimeout(() => {
      this.refs.demoHiLite.loop = true;
      // console.log("Demonstration loop true");
      this.refs.demoHiLite.play();
      // console.log("Demonstration play true");
    }, 4000)
    // console.log("Demonstration trigger child click");
  }
 
  handleVisible (isVisible) {
    // console.log('Demonstration is now %s', isVisible ? 'visible' : 'hidden');

    if (isVisible) {
      clearTimeout(this.triggerTimeout);
      clearTimeout(this.loopTimeout);
      this.refs.demoHiLite.loop = false;
      this.refs.demoHiLite.currentTime = 0;
      this.refs.demoHiLite.play();
      this.triggerTimeout = setTimeout(this.timer, 5500);
    }
    else {
      clearTimeout(this.triggerTimeout);
      clearTimeout(this.loopTimeout);
    }
  }
  render () {
    return (
      <div className="section">
        <div className="demonstration">
          {this.state.mounted && <VisibilitySensor onChange={this.handleVisible}>
            <div className="smartwatch-visibility-sensor" style={{
              position: "absolute",
              width: "10px",
              height: "10px",
              zIndex: 100,
              backgroundColor: "transparent",
              top: "50%",
              left: "50%"
            }} />
          </VisibilitySensor>}
          <div className="demonstration-content">
            <DimensionsWrap heightProp={690 / 1150} widthProp={1150 / 1920} className="demo-phone">
              <video src={require("../../assets/videos/demonstration.mp4")} ref="demoHiLite" width="100%" height="100%" muted />
              <img src={require("../../assets/imgs/videooverlay.png")} alt={"overlay"} />
              <div className={this.state.trigger ? "demo-trigger active" : "demo-trigger"} />
            </DimensionsWrap>
            <div className="hilite-slider">
            <div className="disable-slider" />
              <HiLiteSlider onRef={ref => (this.child = ref)} action={this.handleTrigger} />
            </div>
            <DimensionsWrap heightProp={304 / 635} widthProp={635 / 1920} className={this.props.animated ? "demonstration-info animated shadow" : "demonstration-info shadow"}>
              <div className="demonstration-title">
                <span className="card-title">Capture the moment after it happened</span>
              </div>
              <div className="demonstration-text">
                <span className="card-text">Your smartphone camera has never been so clever before</span>
              </div>
              <a href="#Smartwatch">
                <DimensionsWrap heightProp={37.3 / 42.5} widthProp={42.5 / 1920} className="next-section-arrow">
                  <img src={require("../../assets/svgs/arrows.svg")} alt="arrows" width="100%" height="100%" />
                </DimensionsWrap>
              </a>
            </DimensionsWrap>
            <DimensionsWrap heightProp={102 / 675} widthProp={675 / 1920} className="socourt-horizontal">
              <img src={(require("../../assets/svgs/socourt.svg"))} alt="socourt" width="100%" height="100%" />
            </DimensionsWrap>
          </div>
        </div>
      </div>
    )
  }
}

export default Demonstration;