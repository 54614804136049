import React from 'react'
import ReactFullPage from '@fullpage/react-fullpage'
import {
  Products
} from '../../components'
// import TestComponent from "../../components/TestComponent/TestComponent.js";
import './styles.css'

const anchors = [
  'Products'
]

const Pricing = () => (
  <ReactFullPage
    licenseKey={'2D5DBCF8-87FE4098-96CC2EFD-2CAB588F'}
    controlArrows={false}
    anchors={anchors}
    // navigation
    // slidesNavigation
    // navigationTooltips={anchors}
    // onLeave={(origin, destination, direction) => {
    //   console.log("onLeave event", { origin, destination, direction });
    // }}

    render={({ state, fullpageApi }) => {
      // console.log("render prop change", state, fullpageApi);

      return (
        <div>
          <Products />
        </div>
      )
    }}
  />
)

export default Pricing
