import React from "react";

class BubbleBox extends React.Component {

  divStyle = {
    boxShadow: this.props.boxShadow || "0px 0px 5px 0px #64568E",
    borderRadius: this.props.boxRadius || "20px",
    fontSize: this.props.fontSize || 22,
    ...this.props.divStyle
  };
  spanStyle = {
    ...this.props.spanStyle
  }
  render () {
    return (
      <div
        className="bubblebox"
        style={this.divStyle}>
        <span style={this.props.spanStyle}>{this.props.children}</span>
      </div >
    )
  }
}

export default BubbleBox;