import React from 'react'
import {
  FederationsPricing,
  Features,
  FeatureBox,
  Holder
} from '../../components'
import './mobileStyles.css'

const MobileFederations = () => {
  return (
    <div>
      <FederationsPricing />
      <Features>
        <FeatureBox
          title='Live stream'
          text='Live stream the games of your event to multiple platforms. Embed scoreboard to the stream for maximum fan engagement.'>
          <video src={require('../../assets/videos/VID_20190207_163430.mp4')} controls poster={require('../../assets/imgs/bgrwatch.png')} width='100%' height='100%' />
        </FeatureBox>
        <FeatureBox
          title='Live score'
          text='Easily follow and update the score of each match at your event. Combine with live streaming at any time, by embedding a scoreboard into the stream.'>
          <video src={require('../../assets/videos/VID_20190207_163430.mp4')} controls poster={require('../../assets/imgs/bgrwatch.png')} width='100%' height='100%' />
        </FeatureBox>
        <FeatureBox
          title='Video Replays'
          text='Capture and share instant hi-lites from the games at your event. Get automatic replays after updates in the live score. Share manually or automatically to multiple platforms.'>
          <video src={require('../../assets/videos/VID_20190207_163430.mp4')} controls poster={require('../../assets/imgs/bgrwatch.png')} width='100%' height='100%' />
        </FeatureBox>
        <FeatureBox
          title='Compilations'
          text='Create compilations with your favourite video replays from the event, or get automatic suggestions by SoCourt. Share manually or automatically to multiple platforms.'>
          <video src={require('../../assets/videos/VID_20190207_163430.mp4')} controls poster={require('../../assets/imgs/bgrwatch.png')} width='100%' height='100%' />
        </FeatureBox>
        <FeatureBox
          title='Commercials'
          text='Integrate logo and video ads of your event or sponsors in all generated streams and hi-lites. Watch them go viral among the fans.'>
          <video src={require('../../assets/videos/VID_20190207_163430.mp4')} controls poster={require('../../assets/imgs/bgrwatch.png')} width='100%' height='100%' />
        </FeatureBox>
        <FeatureBox
          title='Empower your audience'
          text='Further engage with your fans by letting them create their own hi-lites, no matter if they are spectating your event on-site or watching it over a stream.'>
          <video src={require('../../assets/videos/VID_20190207_163430.mp4')} controls poster={require('../../assets/imgs/bgrwatch.png')} width='100%' height='100%' />
        </FeatureBox>
        <FeatureBox
          title='Restrict access'
          text='Enable restricted access to the video content streamed in SoCourt. Activate pay-per-view or event code features for the purpose to entertain only your biggest fans.'>
          <video src={require('../../assets/videos/VID_20190207_163430.mp4')} controls poster={require('../../assets/imgs/bgrwatch.png')} width='100%' height='100%' />
        </FeatureBox>
      </Features>
      <Holder />
    </div>
  )
}

export default MobileFederations
