import React from 'react'
import { Link } from 'react-router-dom'
import './mobileStyles.css'

class MobileProducts extends React.Component {
  render () {
    return (
      <div className='mobile-products'>
        <div className='mobile-products-background' />
        <div className='mobile-products-info'>
          <div className='mobile-products-info-title'>
            <span>Biggest innovations are for everyone.</span>
          </div>
          <div className='mobile-products-info-text'>
            <span>Choose how to join the most entertaining sports community </span>
          </div>
        </div>
        <div className='mobile-products-card-container'>
          <div className='mobile-card-image-wrap'>
            <img src={require('../../assets/imgs/sportsmen.png')} alt='sport fans' className='mobile-products-image' />
            <div className='mobile-products-card shadow'>
              <div className='mobile-products-card-title shadow'>
                <span>Players & Fans & Coaches</span>
              </div>
              <div className='mobile-products-card-text'>
                <span>Capture and share instant video replays of the games you play or watch. You decide what’s cool and what the world should see from the game.</span>
                <Link to='/Individuals'>
                  <div className='mobile-pricing-btn join'>
                    Join
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className='mobile-products-card shadow'>
            <div className='mobile-products-card-title shadow'>
              <span>Sport Centers & Academies</span>
            </div>
            <div className='mobile-products-card-text'>
              <span>Let your members create, review and broadcast branded video content from the games and trainings at your fields.</span>
              {/* <Link to="/Clubs">
                <div className="mobile-pricing-btn join">
                  Join
                    </div>
              </Link> */}
              <div className='mobile-pricing-btn join'>
                Coming soon
              </div>
            </div>
          </div>
          <div className='mobile-products-card shadow'>
            <div className='mobile-products-card-title shadow'>
              <span>Sport Events & Tournaments</span>
            </div>
            <div className='mobile-products-card-text'>
              <span>Entertain your audience with a real-time tool for video hi-lites, streaming and live score.</span>
              {/* <Link to="/Federations">
                <div className="mobile-pricing-btn join">
                  Join
                    </div>
              </Link> */}
              <div className='mobile-pricing-btn join'>
                Coming soon
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MobileProducts
