import React from 'react'
import ReactFullPage from '@fullpage/react-fullpage'
import {
  IndividualsPricing,
  // FeatureBox,
  // Features,
  Holder
} from '../../components'
import './styles.css'

const anchors = [
  'Individuals-Pricing',
  'Individuals-Features',
  'Individuals-Features-Extended',
  'Individuals-Holder'
]

const Individuals = () => (
  <ReactFullPage
    licenseKey={'2D5DBCF8-87FE4098-96CC2EFD-2CAB588F'}
    controlArrows={false}
    anchors={anchors}
    navigation
    // slidesNavigation
    // navigationTooltips={anchors}
    // onLeave={(origin, destination, direction) => {
    //   console.log("onLeave event", { origin, destination, direction });
    // }}

    render={({ state, fullpageApi }) => {
      // console.log("render prop change", state, fullpageApi);

      return (
        <div>
          <IndividualsPricing />
          {/* <Features goTo='#Individuals-Features-Extended'>
            <FeatureBox
              title='Replays'
              text='Capture the hi-lites of the game you play or watch. Get instant access to the videos.'>
              <video src={require('../../assets/videos/replays.mov')} poster={require('../../assets/imgs/replaysposter.png')} autoPlay loop muted data-keepplaying width='100%' height='100%' />
            </FeatureBox>
            <FeatureBox
              title='Different views'
              text='Connect multiple phones to capture a single game. Enjoy the same hi-lite from all angles.'>
              <video src={require('../../assets/videos/differentviews.mp4')} poster={require('../../assets/imgs/differentviewsposter.png')} autoPlay loop muted data-keepplaying width='100%' height='100%' />
            </FeatureBox>
            <FeatureBox
              title='Smartwatch friendly'
              text='Comfortably create replays by pressing a button on the SoCourt app for Apple Watch and Android Wear.'>
              <video src={require('../../assets/videos/smartwatch.mov')} poster={require('../../assets/imgs/smartwatchposter.png')} autoPlay loop muted data-keepplaying width='100%' height='100%' />
            </FeatureBox>
            <FeatureBox
              title='Remote control'
              text='Make sure you join a game with at least one active camera. Now you can create replays from all other cameras by sliding the button on your phone.'>
              <video src={require('../../assets/videos/remotecontrol.mp4')} poster={require('../../assets/imgs/remoteposter.png')} autoPlay loop muted data-keepplaying width='100%' height='100%' />
            </FeatureBox>
          </Features>
          <Features goTo='#Individuals-Holder'>
            <FeatureBox
              title='Adjustable length'
              text='Select how many seconds back to start the replay. 20 seconds is by default.'>
              <video src={require('../../assets/videos/length.mp4')} poster={require('../../assets/imgs/lengthposter.png')} autoPlay loop muted data-keepplaying width='100%' height='100%' />
            </FeatureBox>
            <FeatureBox
              title='Save'
              text='Your game with all your highlights deserves to be securely saved in our cloud. Just post it and enjoy watching the videos whenever you want.'>
              <video src={require('../../assets/videos/save.mp4')} poster={require('../../assets/imgs/saveposter.png')} autoPlay loop muted data-keepplaying width='100%' height='100%' />
            </FeatureBox>
            <FeatureBox
              title='Profile'
              text='Scroll through all your games. Organize your favorites videos in different collections.'>
              <video src={require('../../assets/videos/profile.mp4')} poster={require('../../assets/imgs/profileposter.png')} autoPlay loop muted data-keepplaying width='100%' height='100%' />
            </FeatureBox>
            <FeatureBox
              title='Share'
              text='Share your whole game with all its highlights to any social network you prefer.'>
              <video src={require('../../assets/videos/share.mp4')} poster={require('../../assets/imgs/shareposter.png')} autoPlay loop muted data-keepplaying width='100%' height='100%' />
            </FeatureBox>
          </Features> */}
          <Holder cardHeight={535 / 569} cardWidth={569 / 1920} />
        </div>
      )
    }}
  />
)

export default Individuals
