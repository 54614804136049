import React from 'react'
import './mobileStyles.css'

class MobilePartnerBox extends React.Component {
  render () {
    const image = this.props.image
    const description = this.props.description
    const width = this.props.width
    const height = this.props.height

    return (
      <div
        className='mobile-partner-box shadow'>
        <img
          src={require(`../../assets/partners/${image}`)}
          alt={description}
          width={width}
          height={height} />
      </div>
    )
  }
}

export default MobilePartnerBox
