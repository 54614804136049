import React from 'react'
// import ReactDOM from "react-dom";
import './styles.css'

const INITIAL_STATE = {
  playing: {
    // "assemble": false,
    'fix': false,
    'still': false,
    // "info": false,
    'hit': false
  }
}
class VideoBox extends React.Component {
  constructor () {
    super()
    this.state = INITIAL_STATE

    this.zoomTimeout = null
    this.mostRightToggle = this.mostRightToggle.bind(this)
    this.mostLeftToggle = this.mostLeftToggle.bind(this)
    this.togglePlay = this.togglePlay.bind(this)
    this.toggleZoom = this.toggleZoom.bind(this)
    this.handleRightClick = this.handleRightClick.bind(this)
    this.handleLeftClick = this.handleLeftClick.bind(this)
  }
  componentWillUnmount () {
    clearTimeout(this.zoomTimeout)
  }
  mostRightToggle (ref) {
    this.zoomTimeout = setTimeout(() => {
      this.handleRightClick()
    }, 200)
    this.toggleZoom(ref)
  }
  mostLeftToggle (ref) {
    this.zoomTimeout = setTimeout(() => {
      this.handleLeftClick()
    }, 200)
    this.togglePlay(ref)
  }
  togglePlay (ref) {
    // this.refs["assemble"].pause();
    // this.refs["mount"].pause();
    this.refs['fix'].pause()
    // this.refs["info"].pause();

    if (this.refs[ref] && !this.state.playing[ref]) {
      this.refs[ref].play()
      this.setState({ playing: { [ref]: true } })
    } else if (this.refs[ref]) {
      this.refs[ref].pause()
      this.setState({ playing: { [ref]: false } })
    }
  }
  toggleZoom (ref) {
    this.refs['fix'].pause()

    if (this.refs[ref] && !this.state.playing[ref]) {
      this.setState({ playing: { [ref]: true } })
    } else if (this.refs[ref]) {
      this.setState({ playing: { [ref]: false } })
    }
  }

  handleRightClick () {
    this.refs.horizontalScroll.scrollBy({ left: 600, behavior: 'smooth' })
  }
  handleLeftClick () {
    this.refs.horizontalScroll.scrollBy({ left: -600, behavior: 'smooth' })
  }

  render () {
    return (
      <div className='video-box'>
        <div className='video-box-arrow-container'>
          <img src={require('../../assets/svgs/controlarrow.svg')} alt='control arrow left' className='control-arrow left' onClick={this.handleLeftClick} />
          <img src={require('../../assets/svgs/controlarrow.svg')} alt='control arrow right' className='control-arrow right' onClick={this.handleRightClick} />
        </div>
        <div className='video-box-element-container' ref='horizontalScroll'>
          <video src={require('../../assets/videos/holderfix.mp4')} poster={require('../../assets/imgs/nvnplay.png')} className={this.state.playing['fix'] ? 'single-video-container active' : 'single-video-container'} muted id='holder-fix' ref='fix' onClick={() => this.mostLeftToggle('fix')} />
          <img src={require('../../assets/imgs/holderstill.jpg')} alt='holder still' className={this.state.playing['still'] ? 'single-video-container active' : 'single-video-container'} ref='still' onClick={() => this.toggleZoom('still')} />
          <img src={require('../../assets/imgs/holderhit.jpg')} alt='holder hit' className={this.state.playing['hit'] ? 'single-video-container active' : 'single-video-container'} ref='hit' onClick={() => this.mostRightToggle('hit')} />
          {/* <img src={require("../../assets/imgs/productsbackground.png")} alt="test" className={this.state.playing["test"] ? "single-video-container active" : "single-video-container"} ref="test" onClick={() => this.toggleZoom("test")} />
          <video src={require("../../assets/videos/0BAF4D74-7D2D-4EA4-B918-230DD659BFCF.mp4")} className={this.state.playing["assemble"] ? "single-video-container active" : "single-video-container"} id="holder-assemble" ref="assemble" onClick={() => this.togglePlay("assemble")} />
          <video src={require("../../assets/videos/0BAF4D74-7D2D-4EA4-B918-230DD659BFCF.mp4")} className={this.state.playing["mount"] ? "single-video-container active" : "single-video-container"} id="holder-mount" ref="mount" onClick={() => this.togglePlay("mount")} />
          <video src={require("../../assets/videos/0BAF4D74-7D2D-4EA4-B918-230DD659BFCF.mp4")} className={this.state.playing["fix"] ? "single-video-container active" : "single-video-container"} id="holder-fix" ref="fix" onClick={() => this.togglePlay("fix")} />
          <video src={require("../../assets/videos/0BAF4D74-7D2D-4EA4-B918-230DD659BFCF.mp4")} className={this.state.playing["info"] ? "single-video-container active" : "single-video-container"} id="holder-info" ref="info" onClick={() => this.togglePlay("info")} /> */}
        </div>
      </div>
    )
  }
}

export default VideoBox
