import React from 'react'
import { BrowserRouter as Router, Route, Link } from 'react-router-dom'
import { Home, Pricing, Individuals, Clubs, Federations } from '../../screens'
import { ReactComponent as SoCourtLogo } from '../../assets/svgs/logoSoCo.svg'
import { ScrollToTop } from '../../components'
import './mobileStyles.css'

class MobileHeader extends React.Component {
  render () {
    return (
      <Router>
        <ScrollToTop>
          <div className='main'>
            <div className='mobile-header-container shadow'>
              <div className='mobile-header-main'>
                <div className='mobile-header-logo'>
                  <Link to='/'><SoCourtLogo className='mobile-socourt-logo' alt='logo' /></Link>
                </div>
                <div className='mobile-header-navigation'>
                  {/* <Link to="/AboutUs"><span>About</span></Link> */}
                  <Link to='/Pricing'><span>Pricing</span></Link>
                  {/* <Link to="/LogIn"><span>Log in</span></Link> */}
                </div>
              </div>
            </div>
            <Route exact path='/' component={Home} />
            {/* <Route path="/AboutUs" component={AboutUs} /> */}
            <Route path='/Pricing' component={Pricing} />
            {/* <Route path="/LogIn" component={LogIn} /> */}
            <Route path='/Individuals' component={Individuals} />
            <Route path='/Clubs' component={Clubs} />
            <Route path='/Federations' component={Federations} />
          </div>
        </ScrollToTop>
      </Router>

    )
  }
}

export default MobileHeader
