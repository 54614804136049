import React from 'react'
import { BrowserRouter as Router, Route, Link } from 'react-router-dom'
import { Home, AboutUs, LogIn, Pricing, Individuals, Clubs, Federations } from '../../screens'
import { ReactComponent as SoCourtLogo } from '../../assets/svgs/logoSoCo.svg'
import { ScrollToTop } from '../../components'
import './styles.css'

class Header extends React.Component {
  render () {
    return (
      <Router>
        <ScrollToTop>
          <div className='main'>
            <div className='header-container shadow'>
              <div className='header-main'>
                <div className='header-logo'>
                  <Link to='/'><SoCourtLogo className='socourt-logo' alt='logo' /></Link>
                </div>
                <div className='header-navigation'>
                  {/* <Link to="/AboutUs"><span>About</span></Link> */}
                  <Link to='/Pricing'><span>Pricing</span></Link>
                  {/* <Link to="/LogIn"><span>Log in</span></Link> */}
                </div>
              </div>
            </div>
            <Route exact path='/' component={Home} />
            <Route path='/AboutUs' component={AboutUs} />
            <Route path='/Pricing' component={Pricing} />
            <Route path='/LogIn' component={LogIn} />
            <Route path='/Individuals' component={Individuals} />
            <Route path='/Clubs' component={Clubs} />
            <Route path='/Federations' component={Federations} />
          </div>
        </ScrollToTop>
      </Router>
    )
  }
}

export default Header
