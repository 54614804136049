import React from 'react'
import {
  ClubsPricing,
  Features,
  FeatureBox,
  Holder
} from '../../components'
import './mobileStyles.css'

const MobileClubs = () => {
  return (
    <div>
      <ClubsPricing />
      <Features>
        <FeatureBox
          title='Get hi-lites from your fields'
          text='Access all public hi-lites generated with SoCourt at your subscribed fields.'>
          <video src={require('../../assets/videos/VID_20190207_163430.mp4')} controls poster={require('../../assets/imgs/bgrwatch.png')} width='100%' height='100%' />
        </FeatureBox>
        <FeatureBox
          title='Video replays'
          text='Use your profile to create & share video replays from your venue'>
          <video src={require('../../assets/videos/VID_20190207_163430.mp4')} controls poster={require('../../assets/imgs/bgrwatch.png')} width='100%' height='100%' />
        </FeatureBox>
        <FeatureBox
          title='Commercials'
          text='Integrate logo & video ads in the hi-lites from your subscribed fields.'>
          <video src={require('../../assets/videos/VID_20190207_163430.mp4')} controls poster={require('../../assets/imgs/bgrwatch.png')} width='100%' height='100%' />
        </FeatureBox>
      </Features>
      <Holder info />
    </div>
  )
}

export default MobileClubs
