import React from 'react'
import './mobileStyles.css'

class MobileLivestream extends React.Component {
  render () {
    return (
      <div className='mobile-livestream'>
        <img src={require('../../assets/svgs/socourtvertical.svg')} alt='socourt vertical' style={{
          position: 'absolute',
          width: '51px',
          height: '340px',
          bottom: 0,
          left: '5%'
        }} />
        <div className='mobile-livestream-info box-radius-left shadow'>
          <div className='mobile-smartwatch-info-title mobile-title'>
            <span>Livestream with ease</span>
          </div>
          <div className='mobile-smartwatch-info-text mobile-text'>
            <span>Your entire game is worth watching? Start streaming live and keep it recorded for a post-analysis</span>
          </div>
          <img src={require('../../assets/svgs/arrows.svg')} alt='arrow' className='mobile-arrow' style={{ right: '15px' }} />
        </div>
        <img src={require('../../assets/imgs/mobile-fb.png')} alt='facebook stream' className='mobile-facebook-stream' />
        <img src={require('../../assets/imgs/mobile-yt.png')} alt='youtube stream' className='mobile-youtube-stream' />
      </div>
    )
  }
}

export default MobileLivestream
