import React from "react";
import { DimensionsWrap } from "../DimensionsWrap";
import VisibilitySensor from "react-visibility-sensor"
import "./styles.css";

class Smartwatch extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      mounted: false
    }
    this.handleVisible = this.handleVisible.bind(this);
    this.triggerTimeout = null;
    this.loopTimeout = null;
  }
  componentDidMount () {
    this.setState({ mounted: true })
  }
  componentWillUnmount () {
    clearTimeout(this.triggerTimeout);
    clearTimeout(this.loopTimeout);
    this.setState({ mounted: false })
  }
  timer = () => {
    this.loopTimeout = setTimeout(() => {
      this.refs.smartwatchVideo.loop = true;
      // console.log("Smartwatch loop true");
      this.refs.smartwatchVideo.play();
      // console.log("Smartwatch play true");
    }, 4000)
  }

  handleVisible (isVisible) {
    // console.log('Smartwatch is now %s', isVisible ? 'visible' : 'hidden');

    if (isVisible) {
      clearTimeout(this.triggerTimeout);
      clearTimeout(this.loopTimeout);
      this.refs.smartwatchVideo.loop = false;
      this.refs.smartwatchVideo.currentTime = 0;
      this.refs.smartwatchVideo.play();
      this.triggerTimeout = setTimeout(this.timer, 7000);
    }
    else {
      clearTimeout(this.triggerTimeout);
      clearTimeout(this.loopTimeout);
    }
  }

  render () {
    return (
      <div className="section">
        <div className="smartwatch">
          {this.state.mounted && <VisibilitySensor onChange={this.handleVisible}>
            <div className="smartwatch-visibility-sensor" style={{
              position: "absolute",
              width: "10px",
              height: "10px",
              zIndex: 100,
              backgroundColor: "transparent",
              top: "50%",
              left: "50%"
            }} />
          </VisibilitySensor>}
          <div className="smartwatch-video">
            <video src={require("../../assets/videos/smartwatch.mp4")} ref="smartwatchVideo" muted />
          </div>
          <DimensionsWrap heightProp={304 / 760} widthProp={760 / 1920} className={this.props.animated ? "smartwatch-info animated shadow" : "smartwatch-info shadow"}>
            <div className="smartwatch-title">
              <span className="card-title">Command SoCourt from your smartwatch</span>
            </div>
            <div className="smartwatch-text">
              <span className="card-text">Use the smartwatch app while playing. Or slide the button on your smartphone, if it is next to you</span>
            </div>
            <a href="#Livestream">
              <DimensionsWrap heightProp={37.3 / 42.5} widthProp={42.5 / 1920} className="next-section-arrow">
                <img src={require("../../assets/svgs/arrows.svg")} alt="arrows" width="100%" height="100%" />
              </DimensionsWrap>
            </a>
          </DimensionsWrap>
          <DimensionsWrap heightProp={768 / 1150} widthProp={1150 / 1920} className={this.props.animated ? "smartwatch-hand animated" : "smartwatch-hand"}>
            <img src={require("../../assets/imgs/smartwatchhand.png")} alt="smartwatch hand" />
          </DimensionsWrap>
        </div>
      </div>
    )
  }
}

export default Smartwatch;