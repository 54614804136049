import React from 'react'
import './mobileStyles.css'

class MobileIndividualsPricing extends React.Component {
  render () {
    return (
      <div className='mobile-individuals-pricing'>
        <div className='mobile-individuals-pricing-background' />
        <div className='mobile-individuals-pricing-title'>
          <span>Your unique sport moments are just a swipe away</span>
        </div>
        <div className='mobile-individuals-pricing-card-container'>
          <div className='mobile-card-image-wrap'>
            <img src={require('../../assets/svgs/malefan.svg')} alt='sport fans' className='mobile-individuals-pricing-image' />
            <div className='mobile-individuals-pricing-card white shadow'>
              <div className='mobile-individuals-pricing-card-title shadow'>
                <span>FREE</span>
              </div>
              <div className='mobile-individuals-pricing-card-text'>
                <span>0€</span>
                <ul>
                  <li>Video replays</li>
                  <li>Set hi-lite length</li>
                  <li>Add angles</li>
                  <li>Smartwatch friendly</li>
                </ul>
                <a target='_blank' rel='noopener noreferrer' href='https://itunes.apple.com/us/app/socourt/id1039455431'>                  <div className='mobile-pricing-btn join-v2'>
                  <span>Download Now</span>
                </div>
                </a>
              </div>
            </div>
          </div>
          <div className='mobile-individuals-pricing-card blue shadow'>
            <div className='mobile-individuals-pricing-card-title shadow'>
              <span>MONTHLY SUBSCRIPTION</span>
            </div>
            <div className='mobile-individuals-pricing-card-text'>
              <span>4,95€</span>
              <ul>
                <li>Included free plan features</li>
                <li>Adjustable length</li>
                <li>Share</li>
                <li>Profile</li>
                <li>Share</li>
              </ul>
              <a href='#'>
                <div className='mobile-pricing-btn subscribe'>
                  <span>Coming soon</span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MobileIndividualsPricing
