import React from 'react'
import './mobileStyles.css'

class Features extends React.Component {
  render () {
    return (
      <div className='mobile-features'>
        <div className='mobile-features-content-title'>
          <span>Features</span>
        </div>
        <div className='mobile-features-box-wrapper'>{this.props.children}</div>
      </div>
    )
  }
}

export default Features
