import React from 'react'
import {
  Products
} from '../../components'
import './mobileStyles.css'

const MobilePricing = () => {
  return (
    <div>
      <Products />
    </div>
  )
}

export default MobilePricing
