import React from 'react'
import { Footer } from '../../components'
import './mobileStyles.css'

class MobileHolder extends React.Component {
  render () {
    return (
      <div className='mobile-holder'>
        <div className='mobile-holder-title'>
          <span>Buy a Holder</span>
        </div>
        <div className='mobile-holder-card shadow'>
          <div className='mobile-holder-card-info'>
            <div className='mobile-holder-card-title'>
              <span>Benefits</span>
            </div>
            <div className='mobile-holder-card-text'>
              <ul>
                <li>Universal mounting - tripod, wall, pipe and more</li>
                <li>Fix a phone in seconds</li>
                <li>Full ball hit protection</li>
              </ul>
            </div>
            {this.props.info &&
              <div className='mobile-holder-card-info-materials'>
                <div className='mobile-holder-card-title'>
                  <span>+ Info materials</span>
                </div>
                <div className='mobile-holder-card-text'>
                  <span>Placed next to the phone holders, these are inviting your players and coaches to start enjoying SoCourt</span>
                </div>
              </div>
            }
            <div className='mobile-holder-price-text'><span>Price:</span></div>
            <div className='mobile-holder-price-number'><span>20,99€</span></div>
            <a href='#'>
              <div className='mobile-pricing-btn subscribe'>
                <span>Coming soon</span>
              </div>
            </a>
          </div>
        </div>
        <div className='mobile-holder-video-wrapper'>
          {/* {this.props.info &&
            <div className="mobile-holder-video-box">
              <video src={require("../../assets/videos/0BAF4D74-7D2D-4EA4-B918-230DD659BFCF.mp4")} controls poster={require("../../assets/imgs/image1.png")} width="100%" height="auto" />
            </div>} */}
          <div className='mobile-holder-video-box'>
            <video src={require('../../assets/videos/holderfix.mp4')} muted controls poster={require('../../assets/imgs/nvn.png')} width='100%' height='auto' />
          </div>
          <div className='mobile-holder-video-box'>
            <img src={require('../../assets/imgs/holderstill.jpg')} alt='holder still' width='100%' height='auto' />
          </div>
          <div className='mobile-holder-video-box'>
            <img src={require('../../assets/imgs/holderhit.jpg')} alt='holder still' width='100%' height='auto' />
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

export default MobileHolder
