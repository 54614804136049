import React from 'react'
import ReactFullPage from '@fullpage/react-fullpage'
import {
  ClubsPricing,
  Features,
  FeatureBox,
  Holder
} from '../../components'
// import TestComponent from "../../components/TestComponent/TestComponent.js";
import './styles.css'

const anchors = [
  'Clubs-Pricing',
  'Clubs-Features',
  'Clubs-Holder'
]

const Clubs = () => (
  <ReactFullPage
    licenseKey={'2D5DBCF8-87FE4098-96CC2EFD-2CAB588F'}
    controlArrows={false}
    anchors={anchors}
    navigation
    // slidesNavigation
    // navigationTooltips={anchors}
    // onLeave={(origin, destination, direction) => {
    //   console.log("onLeave event", { origin, destination, direction });
    // }}

    render={({ state, fullpageApi }) => {
      // console.log("render prop change", state, fullpageApi);

      return (
        <div>
          <ClubsPricing />
          <Features goTo='#Clubs-Holder'>
            <FeatureBox
              title='Get hi-lites from your fields'
              text='Access all public hi-lites generated with SoCourt at your subscribed fields.'>
              <video src={require('../../assets/videos/VID_20190207_163430.mp4')} autoPlay loop muted data-keepplaying width='100%' height='100%' />
            </FeatureBox>
            <FeatureBox
              title='Video replays'
              text='Use your profile to create & share video replays from your venue'>
              <video src={require('../../assets/videos/VID_20190207_163430.mp4')} autoPlay loop muted data-keepplaying width='100%' height='100%' />
            </FeatureBox>
            <FeatureBox
              title='Commercials'
              text='Integrate logo & video ads in the hi-lites from your subscribed fields.'>
              <video src={require('../../assets/videos/VID_20190207_163430.mp4')} autoPlay loop muted data-keepplaying width='100%' height='100%' />
            </FeatureBox>
          </Features>
          <Holder cardHeight={585 / 569} cardWidth={569 / 1920} info />
        </div>
      )
    }}
  />
)

export default Clubs
