import React from 'react'
import './mobileStyles.css'
class MobileFeatureBox extends React.Component {
  render () {
    return (
      <div className='mobile-feature-box'>
        <div className='mobile-feature-box-visual'>
          {this.props.children}
        </div>
        <div className='mobile-feature-box-title'>
          <span>{this.props.title}</span>
        </div>
        <div className='mobile-feature-box-text'>
          <span>{this.props.text}</span>
        </div>
      </div>
    )
  }
}

export default MobileFeatureBox
