import React from 'react'
import { DimensionsWrap } from '../DimensionsWrap'
import { Link } from 'react-router-dom'
import './styles.css'

class Products extends React.Component {
  render () {
    return (
      <div className='section'>
        <div className='products'>
          <div className='products-content'>
            <div className='products-info'>
              <div className='products-info-title'>
                <span>Biggest innovations are for everyone.</span>
              </div>
              <div className='products-info-text'>
                <span>Choose how to join the most entertaining sports community </span>
              </div>
            </div>
            <div className='products-card-container'>
              <DimensionsWrap heightProp={525 / 415} widthProp={415 / 1970} className='products-card shadow'>
                <div className='products-card-title shadow'>
                  <span>Players & Fans & Coaches</span>
                </div>
                <div className='products-card-text'>
                  <span>Capture and share instant video replays of the games you play or watch. You decide what’s cool and what the world should see from the game.</span>
                  <Link to='/Individuals'>
                    <DimensionsWrap heightProp={55 / 240} widthProp={240 / 1920} className='pricing-btn join'>
                      Join
                    </DimensionsWrap>
                  </Link>
                </div>
              </DimensionsWrap>
              <DimensionsWrap heightProp={525 / 415} widthProp={415 / 1970} className='products-card shadow'>
                <div className='products-card-title shadow'>
                  <span>Sport Centers & Academies</span>
                </div>
                <div className='products-card-text'>
                  <span>Let your members create, review and broadcast branded video content from the games and trainings at your fields.</span>
                  {/* <Link to="/Clubs">
                    <DimensionsWrap heightProp={55 / 240} widthProp={240 / 1920} className="pricing-btn join">
                      Join
                    </DimensionsWrap>
                  </Link> */}
                  <DimensionsWrap heightProp={55 / 240} widthProp={240 / 1920} className='pricing-btn join'>
                    Coming soon
                  </DimensionsWrap>
                </div>
              </DimensionsWrap>
              <DimensionsWrap heightProp={525 / 415} widthProp={415 / 1970} className='products-card shadow'>
                <div className='products-card-title shadow'>
                  <span>Sport Events & Tournaments</span>
                </div>
                <div className='products-card-text'>
                  <span>Entertain your audience with a real-time tool for video hi-lites, streaming and live score.</span>
                  {/* <Link to="/Federations">
                    <DimensionsWrap heightProp={55 / 240} widthProp={240 / 1920} className="pricing-btn join">
                      Join
                    </DimensionsWrap>
                  </Link> */}
                  <DimensionsWrap heightProp={55 / 240} widthProp={240 / 1920} className='pricing-btn join'>
                    Coming soon
                  </DimensionsWrap>
                </div>
              </DimensionsWrap>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Products
